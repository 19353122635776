<template>
    <div class="w-full bg-light-green py-4 px-2 border-2 border-black-500 border-solid relative mb-2">
        <a @click="deleteShape(shapeIndex)"
           class="remove-area-btn cursor-pointer absolute top-0 right-0 w-12 h-12 flex
        items-center justify-center bg-primary-green text-black
        font-bold border-l-2 border-b-2 border-black-500 border-solid">
            <div class="border border-solid border-black w-4"></div>
        </a>
        <h2 class="font-medium font-futura-pt uppercase text-black">Area {{shapeIndex + 1}}</h2>
        <div class="area-measurements py-4 font-futura-pt text-lg font-normal">
            <p>{{areaSqFt.formatted}} feet²</p>
            <p>{{areaSqYd}} yards²</p>
            <p>{{areaAcres}} acres</p>
        </div>
        <div>
            <button
                @click="deleteShape(shapeIndex)"
                class="block mt-2 bg-red-error py-3 px-4 text-white
            uppercase border-2 border-black-500 border-solid font-extrabold">
                Delete Area
            </button>
        </div>
    </div>
</template>
<script>
import { toRaw } from 'vue';

    export default {
        name: 'AreaCard',
        props: {
            shapeIndex: {
                type: Number,
                default: 0
            }
        },
        computed: {
            areaSqM() {
                let area = window.google.maps.geometry.spherical
                    .computeArea(this.$store.state.shapes[this.shapeIndex].getPath());
                return Math.round(area);
            },
            areaSqFt() {

                let area = window.google.maps.geometry.spherical
                    .computeArea(this.$store.state.shapes[this.shapeIndex].getPath());
                area = Math.round(area * 10.76391);
                return {
                    number: area,
                    formatted: area.toLocaleString()
                }
            },
            areaSqYd() {
                let area = window.google.maps.geometry.spherical
                    .computeArea(this.$store.state.shapes[this.shapeIndex].getPath());
                return (area * 1.1959900463011).toLocaleString();
            },
            areaAcres() {
                let area = window.google.maps.geometry.spherical
                    .computeArea(this.$store.state.shapes[this.shapeIndex].getPath());
                return (area * 0.00024710538146717).toLocaleString();
            }
        },
        methods: {
            deleteShape(index) {
                let shape = this.$store.state.shapes[index]
                toRaw(shape).setMap(null)
                // this.$store.commit('decreaseTotalArea', this.areaSqFt.number);
                this.$store.commit('deleteShape', index);
            },
        },
    }
</script>