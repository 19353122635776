<template>
  <div>
    <div id="map" class="w-full h-full"></div>
  </div>
</template>

<script>
import {Loader} from '@googlemaps/js-api-loader';
import {computed} from 'vue';
import {useStore} from "vuex";
import axios from 'axios';

export default {
  name: 'SodMap',
  data() {
    return {
      selectedShape: null,
      mapOptions: {
        center: {
          lat: 35.46633158025032,
          lng: -97.50568501534394
        },
        zoom: 10,
        disableDefaultUI: true,
      }
    }
  },
  setup() {
    const store = useStore();
    const shapes = computed(() => store.state.shapes);

    const totalArea = computed(() => store.getters.totalArea)

    return {
      shapes,
      totalArea
    }
  },
  methods: {
    addUpdateArea(shape) {
      if (window.ignoreCurrentDrawing) {
        shape.setMap(null);
        window.ignoreCurrentDrawing = false;

        return
      }

      shape.setEditable(true);
      this.$store.commit('addUpdateShape', shape);
    },
    savePalletsData(items) {
      this.$store.commit('savePallets', items)
    },

    initialize(google) {
      window.google = google;

      const map = new google.maps.Map(
          document.getElementById("map"), {
            ...this.mapOptions,
            mapTypeControl: true, // Enable the map type control
            mapTypeControlOptions: {
              style: google.maps.MapTypeControlStyle.DROPDOWN_MENU, // Use a dropdown menu for the control
              mapTypeIds: [google.maps.MapTypeId.SATELLITE, 'grey', google.maps.MapTypeId.ROADMAP, google.maps.MapTypeId.HYBRID, google.maps.MapTypeId.TERRAIN], // Specify the map types to include
              position: google.maps.ControlPosition.TOP_RIGHT // Optional: position the control
            }
          }
      );

      const style = [
        {
          "elementType": "geometry", "stylers": [
            {"saturation": -100}
          ]
        }
      ];

      const mapType = new google.maps.StyledMapType(style, {name: "Grayscale"});
      map.mapTypes.set("grey", mapType);
      map.setMapTypeId(google.maps.MapTypeId.HYBRID);

      const input = document.getElementById("pac-input");
      const searchBox = new google.maps.places.SearchBox(input);

      map.addListener("bounds_changed", () => {
          searchBox.setBounds(map.getBounds());
      });
      let markers = [];

      searchBox.addListener("places_changed", () => {
          const places = searchBox.getPlaces();

          if (places.length == 0) {
              return;
          }

          // Clear out the old markers.
          markers.forEach((marker) => {
              marker.setMap(null);
          });
          markers = [];

          // For each place, get the icon, name and location.
          const bounds = new google.maps.LatLngBounds();

          places.forEach((place) => {
              if (!place.geometry || !place.geometry.location) {
                  console.log("Returned place contains no geometry");
                  return;
              }

              const icon = {
                  url: place.icon,
                  size: new google.maps.Size(71, 71),
                  origin: new google.maps.Point(0, 0),
                  anchor: new google.maps.Point(17, 34),
                  scaledSize: new google.maps.Size(25, 25),
              };

              // Create a marker for each place.
              markers.push(
                  new google.maps.Marker({
                      map,
                      icon,
                      title: place.name,
                      position: place.geometry.location,
                  })
              );
              if (place.geometry.viewport) {
                  // Only geocodes have viewport.
                  bounds.union(place.geometry.viewport);
              } else {
                  bounds.extend(place.geometry.location);
              }
          });
          map.fitBounds(bounds);
      });

      const drawingManager = new google.maps.drawing.DrawingManager({
        drawingMode: google.maps.drawing.OverlayType.POLYGON,
        drawingControl: true,
        drawingControlOptions: {
          drawingModes: [
            google.maps.drawing.OverlayType.POLYGON,
          ],
        },
        markerOptions: {
          draggable: true
        },
        polygonOptions: {
          strokeWeight: 0,
          fillOpacity: 0.45,
          editable: true
        },
        map: map
      });

      window.drawingManager = drawingManager;

      google.maps.event.addListener(drawingManager, 'overlaycomplete', (e) => {
        if (e.type === google.maps.drawing.OverlayType.POLYGON) {

          e.overlay.id = Date.now()

          const newShape = e.overlay;
          newShape.type = e.type;

          const th = this;
          google.maps.event.addListener(newShape.getPath(), 'set_at', function () {
            // Path is changed, update the list
            th.addUpdateArea(newShape);
          });

          google.maps.event.addListener(newShape.getPath(), 'insert_at', function () {
            // New point is added, update the list
            th.addUpdateArea(newShape);
          });

          google.maps.event.addListener(newShape.getPath(), 'remove_at', function () {
            // New point is added, update the list
            th.addUpdateArea(newShape);
          });

          this.addUpdateArea(newShape);
        }
        //END overlaycomplete
      });

      //END INIT
    }

  },
  mounted() {
    const loader = new Loader({
      // old: apiKey: "AIzaSyC75_flZLTeckKqmryx9pxK0BTA9O3GXic",
      apiKey: "AIzaSyDCgfYv0ufxgizhpYSHibG4JENvJpchrGE",
      version: "weekly",
      libraries: ["geometry", "drawing", "places"]
    });

    // let requestUrl = `https://eligibility.sc.egov.usda.gov/eligibility/MapAddressVerification?address=6700%20w%20memorial%20rd%20oklahoma%20city&whichapp=RBSIRP`;
    // axios.get(requestUrl)
    //     .then((data) => {
    //         console.log(data);
    //     });

    loader.load()
        .then((google) => {
          this.initialize(google);
        })

    //Load pallets information
    let webflowPalletsUrl = "https://webflow-fetcher.liquidfish.xyz/fetch_mturf_pallets.php"

    axios.get(webflowPalletsUrl)
        .then((data) => {
          this.savePalletsData(data.data.items);
        });
  }
}
</script>