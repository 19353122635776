import { createStore } from 'vuex';
import { toRaw } from 'vue';

// Create a new store instance.
const store = createStore({
    state: {
        shapes: [],
        // totalArea: 0,
        palletsData: []
    },
    getters: {
        totalArea(store) {
            let total = 0;
            store.shapes.forEach(s => {
                const area = window.google.maps.geometry.spherical.computeArea(s.getPath());
                const areaInSqFt = Math.round(area * 10.76391);
                total += areaInSqFt;
            })

            return total;
        }
    },
    mutations: {
        deleteShape(state, shape) {
            state.shapes.splice(shape, 1);
        },
        // decreaseTotalArea(state, area) {
        //     state.totalArea = state.totalArea - area;
        // },
        // increaseTotalArea(state, area) {
        //     state.totalArea = state.totalArea + area;
        // },
        savePallets(state, items) {
            state.palletsData = items.filter(item =>
                item.lastPublished != null && !item.isDraft && !item.isArchived
            );
        },
        addUpdateShape(state, shape) {
            const index = state.shapes.findIndex(s => s.id === shape.id)
            if (index === -1) {
                state.shapes.push(shape)
            } else {
                // state.shapes.splice(shape, 1);
                state.shapes.splice(index, 1, shape);
                // state.shapes[index] = shape
            }


            const arr = []
            state.shapes.forEach(s => {
                arr.push(s)
            })
            state.shapes = arr
        },

        resetAllShapes(state) {
            state.shapes.forEach(s => {
                toRaw(s).setMap(null)
            });

            state.shapes = []
        }
    },
});

export default store;