import { createWebHistory, createRouter } from "vue-router";
import SodCalculator from "../components/SodCalculator";

const routes = [
    {
        path: "/",
        name: "SodCalculator",
        component: SodCalculator,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;