<template>
    <div class="m-4">
        <div class="w-full">
            <!-- Search Bar  -->
            <div class="flex border-2 border-black-500 border-solid">
                <div class="flex flex-col w-full text-left bg-white pt-2 px-3">
                    <span class="uppercase text-xs font-bold text-dark-green">Address</span>
                    <input
                        id="pac-input"
                        class="outline-none address text-lg"
                        placeholder="Fill in your address"
                        type="text">
                </div>

            </div>

            <!--  Map   -->
            <div class="grid grid-cols-12 my-2 border-2 border-black-500 border-solid map-container md:h-75vh h-full">
                <div class="bg-white md:col-span-3 col-span-12 bg-white p-2 max-h-80 md:max-h-full overflow-y-scroll md:order-first order-last">
<!--                    <select v-model="selectedProduct" id="pallets" class="block bg-white py-3 px-4 text-black mt-2-->
<!--                        uppercase border-2 border-black-500 border-solid font-extrabold">-->
<!--                      <option disabled value="null">&#45;&#45;Please choose an option&#45;&#45;</option>-->
<!--                      <option v-for="(p) in pallets" :key="p.id" v-bind:value="p">{{p.fieldData.name}}</option>-->
<!--                    </select>-->


                  <button @click="refreshAll" class="w-full block bg-white py-3 px-4 text-black mt-2
                        border-2 border-black-500 border-solid font-extrabold bg-green-accent">
                    <span>Reset All</span>
                  </button>

                  <div class="w-full dropdown relative">
                    <button @click="toggleDropdown" class="w-full block bg-white py-3 px-4 text-black mt-2
                        border-2 border-black-500 border-solid font-extrabold">
                        <span class="">{{ selectedProductNameOrDefault }}</span>
                    </button>
                    <ul class="dropdown-menu md:absolute z-10 w-full bg-white"
                        v-bind:class="{'hidden' : !dropdownVisible, 'block':dropdownVisible}"
                    >
                      <li @click="chooseProduct(p)" class="w-full block bg-white py-3 px-4 text-black mt-2
                        border-2 border-black-500 border-solid font-extrabold text-center
                        cursor-pointer hover:bg-gray-200"
                          v-for="(p) in pallets" :key="p.id">{{p.fieldData.name}}</li>
                    </ul>
                  </div>

<!--                  <br/>-->

                  <br/>

                    <AreaCard
                        v-for="(shape, index) in shapes"
                        :key="index"
                        :shape-index="index"/>
<!--                    <button v-if="!shapes.length" class="block bg-white py-3 px-4 text-black mt-2-->
<!--                        uppercase border-2 border-black-500 border-solid font-extrabold">-->
<!--                        Add New Area-->
<!--                    </button>-->


                  <div class="grid grid-cols-11">
                    <button @click="cancelCurrent" class="col-span-5 block bg-white py-3 px-1 text-black mt-2
                        border-2 border-black-500 border-solid font-extrabold bg-green-accent">
                      <span>Cancel current</span>
                    </button>
                    <div class="col-span-1"></div>
                    <button @click="completeDrawing" class="col-span-5 block bg-white py-3 px-1 text-black mt-2
                        border-2 border-black-500 border-solid font-extrabold bg-green-accent">
                      <span>Complete drawing</span>
                    </button>
                  </div>


                </div>
                <div class="md:col-span-9 col-span-12 md:border-b-0 border-b-2 md:border-l-2 border-black-500
                border-solid md:order-last order-first">
                    <SodMap class="md:h-full h-80 w-full" />
                </div>
            </div>

            <!-- Area Data -->
            <div class="grid grid-cols-12 my-2 border-2 border-black-500 border-solid">

              <div class="md:col-span-11 col-span-12 py-4 md:px-6 px-4 bg-primary-green
                md:border-r-2 border-solid border-black">

                    <p ref="watchedElement" v-if="showWatchedElement" id="area" class="text-3xl font-extrabold text-black-500">
                        {{totalContent}}
                    </p>

                    <p v-else ref="watchedElement" id="area" class="text-3xl font-extrabold text-black-500">
                        Total = {{totalAreaFormatted}} sqft
                      <br/>
                        Number of pallets: {{numberOfPallets}}
                    </p>

                    <p class="font-lg font-futura-pt font-normal text-black-500">
                      *Please note, this number total is just a guestimate of your square footage area.
                    </p>
              </div>

              <a @click="onSubmitClicked()"
                 class="cursor-pointer md:col-span-1 col-span-12 py-4 px-6 bg-green-accent text-black
                    font-black tracking-wide uppercase text-xl flex justify-center items-center
                   border-t-2 md:border-t-0 border-solid border-black-500">Submit</a>
            </div>

        </div>
    </div>
</template>
<script>
    import SodMap from './SodMap';
    import AreaCard from './AreaCard';
    export default {
        name: 'SodCalculator',
        data() {
          return {
            selectedProduct: null,
            showWatchedElement: true,
            dropdownVisible: false,
          }
        },
        components: {
            SodMap,
            AreaCard
        },
        methods: {
          cancelCurrent() {
            window.ignoreCurrentDrawing = true
            this.completeDrawing()
          },
          completeDrawing() {
            window.drawingManager.setDrawingMode(null);
            this.$nextTick(() => {
              window.drawingManager.setDrawingMode(window.google.maps.drawing.OverlayType.POLYGON);
            });
          },
          refreshAll() {
            window.drawingManager.setDrawingMode(null);
            this.$nextTick(() => {
                this.$store.commit('resetAllShapes')
                window.drawingManager.setDrawingMode(window.google.maps.drawing.OverlayType.POLYGON);
            });
          },
          toggleDropdown() {
            this.dropdownVisible = !this.dropdownVisible;
          },
          chooseProduct(product) {
            this.dropdownVisible = false;
            this.selectedProduct = product;
          },
          onSubmitClicked() {
            if (this.selectedProduct != null){
              window.parent.postMessage({
                action: "openProductPage",
                slug: this.selectedProduct.fieldData.slug,
                qty: this.numberOfPallets
              }, "*"); // Use a specific origin instead of "*" for better security
            }
          },
          checkLineCount() {
            const element = this.$refs.watchedElement;
            if (element) {
              const lineHeight = parseInt(window.getComputedStyle(element).lineHeight, 10);
              const height = element.clientHeight;

              // Assuming default line height if not explicitly set
              const lines = height / (lineHeight || 16);

              if (lines > 1) {
                this.showWatchedElement = false;
              } else {
                this.showWatchedElement = true;
              }
            }
          },
          handleResize() {
            this.showWatchedElement = true;
            // Re-check whenever watchedContent changes
            this.$nextTick(() => {
              this.checkLineCount();
            });
          },
        },
        computed: {
            shapes() {
                return this.$store.state.shapes;
            },
            totalArea() {
                return this.$store.getters.totalArea;
            },
            pallets() {
                return this.$store.state.palletsData;
            },
            totalAreaFormatted() {
                return this.totalArea.toLocaleString();
            },
            numberOfPallets() {
              if (this.selectedProduct != null){
                if (this.selectedProduct.id == '65d5974ad18f3253f961496c') {
                  return Math.ceil(this.totalArea / 400);
                } else {
                  return Math.ceil(this.totalArea / 500);
                }
              }
              else {
                return "Please choose a grass type"
              }
            },
            totalContent() {
              return "Total = " + this.totalAreaFormatted + " sqft " + "| Number of pallets: " + this.numberOfPallets;
            },
            selectedProductNameOrDefault() {
              return this.selectedProduct ? this.selectedProduct.fieldData.name : "Choose Grass Type"
            }
        },
        watch:{
          totalContent() {
            this.showWatchedElement = true;
            // Re-check whenever watchedContent changes
            this.$nextTick(() => {
              this.checkLineCount();
            });
          }
        },
        mounted() {
                      window.addEventListener('resize', this.handleResize);
        },
        beforeUnmount() {
          window.removeEventListener('resize', this.handleResize);
        },
    }
</script>
<style>
    input.address::placeholder {
        color: black;
        font-weight: 700;
        font-size: 1.125rem;
    }
</style>